import { useEffect, useState } from 'preact/hooks';

import { buildImageURL } from './build-image-url';
import localStorageCache, { TTL_ONE_DAY } from './local-storage-cache';
import { IWidgetProps } from './widget-props.interface';
import { isDebug } from '../constants';

const WEB_WIDGETS_KEY = 'web-widgets';

const fetchSupabase = async <R>(
  apiKey: string,
  url: string
): Promise<R | null> => {
  const fetchOptions = {
    method: 'GET',
    headers: new Headers({
      apikey: apiKey,
      Authorization: `Bearer ${apiKey}`,
    }),
  };

  const res = await fetch(url, fetchOptions);

  if (!res.ok) return null;

  return (await res.json()) as R;
};

type WebWidgetCustomElement = {
  id: string;
  icon: 'calendar' | 'link';
  title: string;
  link: string;
};

type WebWidgetGroupEmployee = {
  employee_id: string;
  user_id: string;
  image_url: string;
};

export const WEB_WIDGET_GROUP_DEFAULTS: Pick<
  WebWidgetGroup,
  'subtitle' | 'call_to_action'
> = {
  subtitle: 'Schreiben Sie uns',
  call_to_action: 'Chatten Sie mit uns - Wir sind für Sie da!',
};

type WebWidgetGroup = {
  id: string;
  name: string;
  subtitle: string;
  call_to_action: string;

  whatsapp_channel_link?: string;
  sms_channel_link?: string;
  facebook_channel_link?: string;
  instagram_channel_link?: string;
  email_channel_link?: string;

  web_widget_custom_element: WebWidgetCustomElement[];
  web_widget_group_employee: WebWidgetGroupEmployee[];
};

export const WEB_WIDGET_DEFAULTS: Pick<
  WebWidget,
  | 'font_color'
  | 'border_color'
  | 'border_radius'
  | 'border_width'
  | 'background_color'
  | 'welcome_message'
  | 'content_button_background_color'
  | 'toggle_button_border_radius'
  | 'header_background_color'
  | 'welcome_title'
  | 'position'
> = {
  font_color: '#333',
  border_color: '#333',
  border_radius: 24,
  border_width: 3,
  background_color: '#fff',
  welcome_message: 'Wie können wir Ihnen weiterhelfen? 😊',
  content_button_background_color: '#fff',
  toggle_button_border_radius: 9999,
  header_background_color: '#fff',
  welcome_title: 'Wo können wir Ihnen behilflich sein?',
  position: 'bottom_right',
};

export type WebWidget = {
  id: string;
  name: string;
  data_privacy_url: string | null;
  organisation_id: string;
  welcome_message: string;
  border_color: string;
  border_radius: number;
  border_width: number;
  font_color: string;
  image_url: string;
  position: 'bottom_left' | 'bottom_right';
  background_color: string;
  content_button_background_color: string;
  toggle_button_border_radius: number;
  header_background_color: string;
  welcome_title: string;

  web_widget_group: WebWidgetGroup[];
};

const hasGroupWithChannelLink = (widget: WebWidget): boolean => {
  if (!widget.web_widget_group) {
    return false;
  }

  return widget.web_widget_group.some(groupHasAChannelLink);
};

const groupHasAChannelLink = (group: WebWidgetGroup): boolean => {
  return Boolean(
    group.whatsapp_channel_link ||
      group.sms_channel_link ||
      group.facebook_channel_link ||
      group.instagram_channel_link ||
      group.email_channel_link ||
      group.web_widget_custom_element.length > 0
  );
};

const fetchWebWidgets = async (
  supabaseConfig: { url: string; anonKey: string },
  ref: { organisation_id?: string; web_widget_id?: string },
  disable_cache?: boolean
): Promise<WebWidget[] | null> => {
  const url = new URL(`${supabaseConfig.url}/rest/v1/web_widget`);
  url.searchParams.append(
    'select',
    'id,name,font_color,border_width,border_color,border_radius,content_button_background_color,toggle_button_border_radius,header_background_color,welcome_title,data_privacy_url,organisation_id,welcome_message,position,background_color,web_widget_group(id,name,subtitle,call_to_action,whatsapp_channel_link,sms_channel_link,facebook_channel_link,instagram_channel_link,email_channel_link,web_widget_custom_element(id,icon,title,link),web_widget_group_employee(employee_id,user_id))'
  );
  url.searchParams.append('order', 'name.asc');

  if (ref.web_widget_id) {
    url.searchParams.append('id', `eq.${ref.web_widget_id}`);
  } else if (ref.organisation_id) {
    url.searchParams.append('organisation_id', `eq.${ref.organisation_id}`);
  }

  const res = await fetchSupabase<WebWidget[]>(
    supabaseConfig.anonKey,
    url.toString()
  );

  if (Array.isArray(res)) {
    return res.filter(hasGroupWithChannelLink).map((w) => ({
      ...w,
      web_widget_group: w.web_widget_group
        .filter(groupHasAChannelLink)
        .map((g) => ({
          ...g,
          web_widget_group_employee: g.web_widget_group_employee.map((e) => ({
            ...e,
            image_url: buildImageURL({
              supabaseUrl: supabaseConfig.url,
              bucketName: 'profile_images',
              pathElements: [e.user_id],
              randomize: disable_cache,
            }),
          })),
        })),
      image_url: buildImageURL({
        supabaseUrl: supabaseConfig.url,
        bucketName: 'contact_widget_images',
        pathElements: [w.organisation_id, w.id],
        randomize: disable_cache,
      }),
    }));
  }
  return res;
};

export const useWebWidgets = (
  props: Pick<
    IWidgetProps,
    'organisation_id' | 'web_widget_id' | 'disable_cache'
  >,
  supabaseConfig?: { url: string; anonKey: string }
): { isLoading: boolean; widgets: WebWidget[] | null | undefined } => {
  const [widgets, setWidgets] = useState<WebWidget[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!supabaseConfig || (!props.organisation_id && !props.web_widget_id)) {
      return;
    }

    const fetchWidgets = async () => {
      setIsLoading(true);
      let widgets = localStorageCache.get<WebWidget[]>(WEB_WIDGETS_KEY);
      if (!widgets || props?.disable_cache || isDebug()) {
        widgets = await fetchWebWidgets(
          supabaseConfig,
          {
            web_widget_id: props.web_widget_id,
            organisation_id: props.organisation_id,
          },
          props.disable_cache
        );
        // set widgets in local storage with 1 day expiry
        localStorageCache.set(WEB_WIDGETS_KEY, widgets, TTL_ONE_DAY);
      }

      setWidgets(widgets);
      setIsLoading(false);
    };

    fetchWidgets();
  }, [props.organisation_id, props.web_widget_id, supabaseConfig]);

  return { widgets, isLoading };
};
