import clsx from 'clsx';
import { h, JSX } from 'preact';
import { useState } from 'preact/hooks';

export function ImageHeaderWithFallback({
  backgroundColor,
  src,
  ...props
}: JSX.HTMLAttributes<HTMLImageElement> & { backgroundColor: string }) {
  const [loading, setLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);

  if (hasError || !src) {
    return null;
  }

  return (
    <div
      style={{
        backgroundColor,
      }}
      className="mateo-justify-left mateo-flex mateo-px-6 mateo-pt-6"
    >
      <img
        alt="Logo"
        onError={() => setHasError(true)}
        onLoad={() => setLoading(false)}
        className={clsx(
          'mateo-h-12 mateo-w-12 mateo-cursor-pointer mateo-rounded-sm',
          loading ? 'mateo-hidden' : ''
        )}
        src={src}
        {...props}
      />
    </div>
  );
}
