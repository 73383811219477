export type BuildImageURLProps = {
  supabaseUrl: string;
  bucketName: string;
  pathElements: string[];
  randomize?: boolean;
};

export const buildImageURL = ({
  supabaseUrl,
  bucketName,
  pathElements,
  randomize,
}: BuildImageURLProps) =>
  `${supabaseUrl.replace(
    /\/$/,
    ''
  )}/storage/v1/object/public/${bucketName}/${pathElements.join('/')}${randomize ? `?random=${Math.random()}` : ''}`;
