import { Database } from '@hellomateo/supabase';
import { Calendar, Link } from 'lucide-preact';
import { h } from 'preact';

export const LucideIcon = ({
  name,
  ...props
}: Record<string, unknown> & {
  name: Database['public']['Enums']['icon_name'];
}) => {
  switch (name) {
    case 'calendar':
      return <Calendar {...props} />;
    case 'link':
      return <Link {...props} />;
    default:
      return null;
  }
};
