import { h, JSX } from 'preact';
import { useState } from 'preact/hooks';

export function ImageWithFallback({
  style,
  ...props
}: JSX.HTMLAttributes<HTMLImageElement>) {
  const [loading, setLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);

  return (
    <img
      alt="Logo"
      style={
        loading || hasError
          ? { ...(style || ({} as any)), display: 'none' }
          : style
      }
      onError={() => setHasError(true)}
      onLoad={() => setLoading(false)}
      {...props}
    />
  );
}
