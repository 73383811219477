import habitat from 'preact-habitat';

import { WidgetHost } from './WidgetHost';

const _habitat = habitat(WidgetHost);

_habitat.render({
  selector: '[data-widget-host="mateo"]',
  clean: true,
});
