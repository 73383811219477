export const SHADOW = '0 8px 15px 1px rgba(0, 0, 0, 0.14)';

export const isDebug = () =>
  process.env.NODE_ENV === 'development' ||
  ['127.0.0.1', 'localhost'].includes(
    new URL(document.location.href).hostname
  ) ||
  new URL(document.location.href).hostname.startsWith('192.168.') ||
  new URL(document.location.href).hostname.startsWith('10.');

const loadEnv = () => {
  return {
    url: process.env.SUPABASE_URL!,
    anonKey: process.env.SUPABASE_ANON_KEY!,
  };
};

export const env = loadEnv();
