import { h, VNode, JSX } from 'preact';

const MateoLogo = (props: JSX.SVGAttributes<SVGSVGElement>): VNode => (
  <svg
    className="mateo-h-4 mateo-w-auto"
    width="1438px"
    height="448px"
    viewBox="0 0 1438 448"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>Mateo Logo</title>
    <g id="9" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="MATEO"
        transform="translate(390.040000, 113.440000)"
        fill="#333333"
        fillRule="nonzero"
      >
        <path
          d="M0,217.56 L0,3.92 L66.64,3.92 L103.6,140.84 C106.96,151.853333 108.64,163.986667 108.64,177.24 L113.12,177.24 C113.12,163.986667 114.8,151.853333 118.16,140.84 L154.84,3.92 L222.04,3.92 L222.04,217.56 L181.72,217.56 L181.72,70.84 C181.72,60.0133333 182.28,49 183.4,37.8 L178.92,37.24 C177.24,48.44 174.813333,59.64 171.64,70.84 L130.76,217.56 L91.28,217.56 L49.84,70.84 C47.04,61.1333333 44.7066667,49.9333333 42.84,37.24 L38.36,37.8 C39.2933333,45.4533333 39.8533333,51.66 40.04,56.42 C40.2266667,61.18 40.32,65.9866667 40.32,70.84 L40.32,217.56 L0,217.56 Z"
          id="Path"
        />
        <path
          d="M285.32,217.56 L243.04,217.56 L318.36,3.92 L362.6,3.92 L437.92,217.56 L395.64,217.56 L381.08,173.6 L299.6,173.6 L285.32,217.56 Z M311.08,138.32 L369.88,138.32 L347.76,71.4 C344.4,60.5733333 342.72,51.52 342.72,44.24 L338.24,44.24 C338.24,50.96 336.466667,60.0133333 332.92,71.4 L311.08,138.32 Z"
          id="Shape"
        />
        <polygon
          id="Path"
          points="523.6 217.56 483.28 217.56 483.28 40.04 418.04 40.04 418.04 3.92 588.56 3.92 588.56 40.04 523.6 40.04"
        />
        <polygon
          id="Path"
          points="753.2 217.56 610.68 217.56 610.68 3.92 753.2 3.92 753.2 40.04 651 40.04 651 92.68 742.56 92.68 742.56 128.52 651 128.52 651 181.44 753.2 181.44"
        />
        <path
          d="M878.64,221.48 C856.053333,221.48 836.966667,216.486667 821.38,206.5 C805.793333,196.513333 794.173333,183.12 786.52,166.32 C778.866667,149.52 775.04,130.946667 775.04,110.6 C775.04,90.2533333 778.866667,71.7266667 786.52,55.02 C794.173333,38.3133333 805.793333,24.9666667 821.38,14.98 C836.966667,4.99333333 856.053333,0 878.64,0 C901.413333,0 920.593333,4.99333333 936.18,14.98 C951.766667,24.9666667 963.386667,38.3133333 971.04,55.02 C978.693333,71.7266667 982.52,90.2533333 982.52,110.6 C982.52,130.946667 978.693333,149.52 971.04,166.32 C963.386667,183.12 951.766667,196.513333 936.18,206.5 C920.593333,216.486667 901.413333,221.48 878.64,221.48 Z M878.64,185.36 C898.053333,185.36 913.22,178.546667 924.14,164.92 C935.06,151.293333 940.52,133.186667 940.52,110.6 C940.52,88.0133333 935.06,70 924.14,56.56 C913.22,43.12 898.053333,36.4 878.64,36.4 C859.413333,36.4 844.34,43.1666667 833.42,56.7 C822.5,70.2333333 817.04,88.2 817.04,110.6 C817.04,133.186667 822.5,151.293333 833.42,164.92 C844.34,178.546667 859.413333,185.36 878.64,185.36 Z"
          id="Shape"
        />
      </g>
      <g
        id="Group"
        transform="translate(53.000000, 118.000000)"
        fill="#333333"
        fillRule="nonzero"
      >
        <path
          d="M46.6203225,141.824295 C43.3323896,148.328799 47.9061275,156 55.2056512,156 C58.0458651,156 60.7285568,154.764913 62.58389,152.61833 C72.981458,140.574894 107.078421,98.5360886 110.638258,61.537779 C110.862108,59.5278397 111,57.4891357 111,55.4161903 C111,53.7334783 110.905088,52.2377138 110.768984,50.8318332 C108.224239,19.9781241 80.4344924,-3.72905247 48.0351573,0.486702173 C23.2125685,3.72091316 3.20412695,24.0843428 0.370468777,48.9218571 C-3.10547302,79.4557027 18.2980214,105.756305 46.9440545,110.179165 C51.3745279,110.869518 54.2576524,115.171797 53.0203052,119.477297 L46.6203225,141.824295 Z"
          id="Path"
          transform="translate(55.500000, 78.000000) rotate(-360.000000) translate(-55.500000, -78.000000) "
        />
        <path
          d="M146.619111,197.824295 C143.332956,204.328799 147.90492,212 155.204467,212 C158.044691,212 160.723723,210.764913 162.58273,208.61833 C172.983999,196.574894 207.077404,154.536089 210.641837,117.537779 C210.862108,115.526045 211,113.487341 211,111.41619 C211,109.733478 210.905088,108.237714 210.768983,106.831833 C208.22423,75.9781241 180.434394,52.2709475 148.034955,56.4867022 C123.212286,59.7209132 103.201946,80.0843428 100.370112,104.926459 C96.8959474,135.456623 118.299557,161.756305 146.943848,166.179165 C151.374336,166.869518 154.25747,171.171797 153.020118,175.477297 L146.619111,197.824295 Z"
          id="Path"
          transform="translate(155.500000, 134.000000) rotate(-540.000000) translate(-155.500000, -134.000000) "
        />
      </g>
    </g>
  </svg>
);

export default MateoLogo;
