import { useMemo } from 'preact/hooks';

import { buildImageURL } from './build-image-url';
import { IConfiguration, IWidgetGroup } from './configuration.interface';
import { ensureHexColor } from './ensure-hex-color';
import {
  WebWidget,
  WEB_WIDGET_DEFAULTS,
  useWebWidgets,
} from './use-web-widgets';
import { IWidgetProps } from './widget-props.interface';

const encodeWhatsAppLink = (link: string) => {
  // for some reason using `new URL()` does not work
  if (link.includes('?text')) {
    const pos = link.indexOf('?text=');
    const text = link.substring(pos + 6);
    link = `${link.substring(0, pos)}?text=${encodeURIComponent(text)}`;
  }
  return link;
};

const partialConfigFromProps = (
  props: IWidgetProps
): Partial<IConfiguration> => ({
  organisation_id: props.organisation_id,
  data_privacy_url: props.data_privacy_url,
  groups: [
    {
      email: props.channels?.email,
      facebook: props.channels?.facebook,
      instagram: props.channels?.instagram,
      sms: props.channels?.sms,
      whatsapp: props.channels?.whatsapp,
      custom_elements: [],
      employees: [],
    },
  ],
  image_url: props.image_url,
  position: props?.position,
  welcome_message: props.welcome_message,
  welcome_title: props.welcome_title,
  border_color: ensureHexColor(props.border_color),
  border_radius: props.border_radius,
  content_button_background_color: ensureHexColor(
    props.content_button_background_color
  ),
  toggle_button_border_radius: props.toggle_button_border_radius,
  header_background_color: ensureHexColor(props.header_background_color),
  font_color: ensureHexColor(props.font_color),
  background_color: props.background_color,
});

const configFromProps = (
  props: IWidgetProps,
  supabaseConfig?: { url: string; anonKey: string }
): IConfiguration => ({
  organisation_id: props.organisation_id,
  data_privacy_url: props.data_privacy_url,
  groups: [
    {
      email: props.channels?.email,
      facebook: props.channels?.facebook,
      instagram: props.channels?.instagram,
      sms: props.channels?.sms,
      whatsapp: props.channels?.whatsapp,
      custom_elements: [],
      employees: [],
    },
  ],
  image_url:
    props.image_url ||
    (supabaseConfig && props.organisation_id && props.web_widget_id
      ? buildImageURL({
          supabaseUrl: supabaseConfig.url,
          bucketName: 'contact_widget_images',
          pathElements: [props.organisation_id, props.web_widget_id],
          randomize: props.disable_cache,
        })
      : undefined),
  position: props?.position || WEB_WIDGET_DEFAULTS.position,
  welcome_title: props.welcome_title || WEB_WIDGET_DEFAULTS.welcome_title,
  border_color: ensureHexColor(
    props.border_color || WEB_WIDGET_DEFAULTS.border_color
  ),
  border_radius: props.border_radius || WEB_WIDGET_DEFAULTS.border_radius,
  border_width: props.border_width || WEB_WIDGET_DEFAULTS.border_width,
  content_button_background_color: ensureHexColor(
    props.content_button_background_color ||
      WEB_WIDGET_DEFAULTS.content_button_background_color
  ),
  toggle_button_border_radius:
    props.toggle_button_border_radius ||
    WEB_WIDGET_DEFAULTS.toggle_button_border_radius,
  header_background_color: ensureHexColor(
    props.header_background_color || WEB_WIDGET_DEFAULTS.header_background_color
  ),
  font_color: ensureHexColor(
    props.font_color || WEB_WIDGET_DEFAULTS.font_color
  ),

  welcome_message: props.welcome_message || WEB_WIDGET_DEFAULTS.welcome_message,
  background_color:
    props.background_color || WEB_WIDGET_DEFAULTS.background_color,
});

const resolveProperty = <T extends keyof WebWidget & keyof IConfiguration>(
  widgets: WebWidget[] | null | undefined,
  partialConfig: Partial<IConfiguration>,
  defaultConfig: IConfiguration,
  prop: T
): WebWidget[T] | IConfiguration[T] => {
  const isOk = (value: any): value is WebWidget[T] => value !== undefined;

  if (isOk(partialConfig[prop])) {
    return partialConfig[prop] as WebWidget[T];
  }

  if (widgets) {
    const widget = widgets.find((w) => isOk(w[prop]));
    if (widget) {
      return widget[prop];
    }
  }

  return defaultConfig[prop];
};

// merge props with fetched widget data
export const useConfig = (
  props: IWidgetProps,
  supabaseConfig?: { url: string; anonKey: string }
): IConfiguration & { loading: boolean } => {
  const { widgets, isLoading } = useWebWidgets(props, supabaseConfig);

  return useMemo<IConfiguration & { loading: boolean }>(() => {
    const loading = Boolean(
      (props.organisation_id || props.web_widget_id) &&
        supabaseConfig &&
        isLoading
    );

    const partialConfig = partialConfigFromProps(props);
    const defaultConfig = configFromProps(props, supabaseConfig);

    if (
      !loading &&
      (widgets ?? []).length === 0 &&
      Object.keys(props.channels ?? {}).length > 0
    ) {
      return { loading: false, ...defaultConfig };
    }

    return {
      loading,
      groups: (widgets ?? []).flatMap((w) =>
        w.web_widget_group.map(
          (g) =>
            ({
              id: g.id,
              name: g.name,
              subtitle: g.subtitle,
              call_to_action: g.call_to_action,
              sms: g.sms_channel_link,
              email: g.email_channel_link,
              whatsapp: g.whatsapp_channel_link
                ? encodeWhatsAppLink(g.whatsapp_channel_link)
                : undefined,
              instagram: g.instagram_channel_link,
              facebook: g.facebook_channel_link,
              custom_elements: g.web_widget_custom_element ?? [],
              employees: g.web_widget_group_employee,
            }) satisfies IWidgetGroup
        )
      ),
      border_color: resolveProperty(
        widgets,
        partialConfig,
        defaultConfig,
        'border_color'
      ),
      border_radius: resolveProperty(
        widgets,
        partialConfig,
        defaultConfig,
        'border_radius'
      ),
      border_width: resolveProperty(
        widgets,
        partialConfig,
        defaultConfig,
        'border_width'
      ),
      content_button_background_color: resolveProperty(
        widgets,
        partialConfig,
        defaultConfig,
        'content_button_background_color'
      ),
      toggle_button_border_radius: resolveProperty(
        widgets,
        partialConfig,
        defaultConfig,
        'toggle_button_border_radius'
      ),
      header_background_color: resolveProperty(
        widgets,
        partialConfig,
        defaultConfig,
        'header_background_color'
      ),
      data_privacy_url: resolveProperty(
        widgets,
        partialConfig,
        defaultConfig,
        'data_privacy_url'
      ),
      font_color: resolveProperty(
        widgets,
        partialConfig,
        defaultConfig,
        'font_color'
      ),
      image_url: resolveProperty(
        widgets,
        partialConfig,
        defaultConfig,
        'image_url'
      ),
      organisation_id: resolveProperty(
        widgets,
        partialConfig,
        defaultConfig,
        'organisation_id'
      ),
      welcome_message: resolveProperty(
        widgets,
        partialConfig,
        defaultConfig,
        'welcome_message'
      ),
      position: resolveProperty(
        widgets,
        partialConfig,
        defaultConfig,
        'position'
      ),
      background_color: resolveProperty(
        widgets,
        partialConfig,
        defaultConfig,
        'background_color'
      ),
      welcome_title: resolveProperty(
        widgets,
        partialConfig,
        defaultConfig,
        'welcome_title'
      ),
    } satisfies IConfiguration & { loading: boolean };
  }, [props, supabaseConfig, isLoading, widgets]);
};
