import { useEffect } from 'preact/hooks';

const WHITELISTED_IDS = [
  'mateo-widget-bubble',
  'mateo-widget-prompt',
  'mateo-widget-channel',
  'mateo-widget-widget',
];

/**
 * Hook that alerts clicks outside of the passed ref
 */
export function useOnOutsideClick(onClick: () => void): void {
  /**
   * Alert if clicked on outside of element
   */
  useEffect(() => {
    if (process.env.DISABLE_SHADOW_DOM === 'true') {
      return;
    }

    // TODO: Check if required after using pointer events now
    function handleClickOutside(event: MouseEvent) {
      const shadowDOMIndex = event
        .composedPath()
        .findIndex(
          (path) => (path as HTMLElement).nodeName === '#document-fragment'
        );

      if (shadowDOMIndex === -1) {
        // No shadow DOM found, so outside click
        onClick();
        return;
      }

      // Array goes from deepest to highest element, so "next" element is the one before the shadow root
      const nextElement = event.composedPath()[
        shadowDOMIndex - 1
      ] as HTMLElement;

      if (nextElement.id !== 'mateo-widget') {
        // Maybe there are other shadow doms on the web page, so we need to check if mateo widget does not exist
        onClick();
        return;
      }

      // Check if any whitelisted element is clicked
      const elements = event
        .composedPath()
        .slice(0, shadowDOMIndex - 1)
        .map((path) => (path as HTMLElement).id);

      if (elements.some((element) => WHITELISTED_IDS.includes(element))) {
        // No outside click
        return;
      }

      onClick();
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClick]);
}
