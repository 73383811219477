const LOCAL_STORAGE_KEY = 'mateo-contact-widget-v1';

export const TTL_ONE_DAY = 24 * 60 * 60 * 1000;

const buildKey = (key: string) => `${LOCAL_STORAGE_KEY}$${key}`;

const setItemInLocalStorageWithExpiry = <T>(
  key: string,
  value: T,
  ttl: number
) => {
  const now = new Date();

  const item = {
    value,
    expiry: now.getTime() + ttl,
  };

  localStorage.setItem(buildKey(key), JSON.stringify(item));
};

const getItemFromLocalStorageWithExpiry = <T>(key: string): T | null => {
  const itemStr = localStorage.getItem(buildKey(key));

  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(buildKey(key));
    return null;
  }

  return item.value;
};

export default {
  set: setItemInLocalStorageWithExpiry,
  get: getItemFromLocalStorageWithExpiry,
};
