import { h } from 'preact';
import { useState } from 'preact/hooks';

import { IWidgetGroup } from './lib';

export interface EmployeesImagesProps {
  borderColor: string;
  employees: IWidgetGroup['employees'];
}

/**
 * Renders a list of employee images.
 * This component automatically handles empty states, errors, etc.
 * @param employees - The list of employees to render.
 */
export const EmployeesImages: React.FC<EmployeesImagesProps> = ({
  employees,
  borderColor,
}) => {
  const [usableImages, setUsableImages] = useState<
    IWidgetGroup['employees'][0]['image_url'][]
  >(() =>
    employees
      .filter((employee) => Boolean(employee.image_url))
      .map((employee) => employee.image_url)
  );

  return usableImages.length > 0 ? (
    <ul className="mateo-m-0 mateo-flex -mateo-space-x-4 mateo-p-0">
      {usableImages.map((image, index) => (
        <li className="mateo-list-none mateo-p-0" key={image}>
          <img
            className="mateo-h-12 mateo-w-12 mateo-rounded-full mateo-border-4 mateo-border-solid mateo-object-cover"
            style={{
              borderColor,
            }}
            alt={`Employee no. ${index + 1}`}
            src={image}
            onError={() => {
              setUsableImages((images) =>
                images.filter((currentImage) => currentImage !== image)
              );
            }}
          />
        </li>
      ))}
    </ul>
  ) : null;
};
