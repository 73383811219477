import clsx from 'clsx';
import { h, FunctionComponent } from 'preact';
import tinyColor from 'tinycolor2';

import './style.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import MateoLogo from './svgs/mateo';

export type PrivacyBrandFooterProps = {
  dataPrivacyUrl?: string | null;
  organisationId?: string | null;
  backgroundColor: string;
};

export const PrivacyBrandFooter: FunctionComponent<PrivacyBrandFooterProps> = ({
  dataPrivacyUrl,
  organisationId,
  backgroundColor,
}) => {
  return (
    <div
      className={clsx(
        'mateo-pt-4',
        tinyColor(backgroundColor).isDark() ? 'mateo-invert' : null
      )}
    >
      <a
        href={`https://hellomateo.de?utm_source=customerwebsite&utm_medium=webwidget&utm_campaign=widgetv2&utm_id=webwidget${
          organisationId ? `&utm_organisation_id=${organisationId}` : ''
        }`}
        target="_blank"
        rel="noreferrer"
        className="mateo-gap-x-2"
      >
        <div className="mateo-flex mateo-cursor-pointer mateo-flex-row mateo-items-center mateo-justify-center mateo-gap-x-1 mateo-text-xs">
          <div className="mateo-text-neutral-800">Powered by</div>

          <MateoLogo />
        </div>
      </a>
      {dataPrivacyUrl && (
        <a href={dataPrivacyUrl} target="_blank" rel="noreferrer">
          <div className="mateo-flex mateo-cursor-pointer mateo-flex-col mateo-items-center mateo-justify-center mateo-pt-2 mateo-text-xs mateo-text-neutral-500">
            Datenschutzhinweise
          </div>
        </a>
      )}
    </div>
  );
};
