import { VNode, h, render } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';

import Widget from './Widget';
import { env } from './constants';
import { IWidgetProps } from './lib/widget-props.interface';

const ShadowDomWidgetHost = (props: IWidgetProps): VNode | null => {
  const ref = useRef<HTMLDivElement>(null);
  const [styleSheetLoaded, setStyleSheetLoaded] = useState(false);

  useEffect(() => {
    if (!ref.current || !env || !ref.current.attachShadow) return;

    const fontStyles: string[] = [];

    const shadowRoot = ref.current.attachShadow({ mode: 'open' });

    render(<Widget {...props} />, shadowRoot);

    const linkElement = document.createElement('link');
    linkElement.type = 'text/css';
    linkElement.rel = 'stylesheet';
    const url = `${
      env.url
    }/storage/v1/object/public/contact_widget/contact-widget${
      props.previewBranch
        ? `.preview$${props.previewBranch.replace(new RegExp('/', 'g'), '-')}`
        : ''
    }.css${props.previewBranch ? `?now=${new Date().toISOString()}` : ''}`;
    linkElement.href = url;
    // important for the onload to work
    // https://stackoverflow.com/questions/49993633/uncaught-domexception-failed-to-read-the-cssrules-property
    linkElement.crossOrigin = 'anonymous';
    // @font-face is not supported in shadow dom yet:
    // https://stackoverflow.com/questions/63710162/how-to-load-font-face-in-dynamically-loaded-styles-of-web-component-with-shadow
    linkElement.onload = (event) => {
      // @ts-ignore
      for (let i = 0; i < event.currentTarget.sheet.cssRules.length; i++) {
        // @ts-ignore
        if (event.currentTarget.sheet.cssRules[i].type === 5) {
          // type 5 is @font-face
          const split = url.split('/');
          const stylePath = split.slice(0, split.length - 1).join('/');
          // @ts-ignore
          let cssText = event.currentTarget.sheet.cssRules[i].cssText;
          cssText = cssText.replace(
            // relative paths
            /url\s*\(\s*[\'"]?(?!((\/)|((?:https?:)?\/\/)|(?:data\:?:)))([^\'"\)]+)[\'"]?\s*\)/g,
            `url("${stylePath}/$4")`
          );

          const st = document.createElement('style');
          const id = `font-face-${i}`;
          st.id = id;
          st.appendChild(document.createTextNode(cssText));
          document.getElementsByTagName('head')[0].appendChild(st);
          fontStyles.push(id);
        }
      }
      setStyleSheetLoaded(() => true);
    };

    shadowRoot.appendChild(linkElement);

    return () => {
      fontStyles.forEach((id) => {
        document.getElementById(id)?.remove();
      });
    };
  }, [props]);

  return (
    <div
      ref={ref}
      style={{
        display: styleSheetLoaded ? 'block' : 'none',
      }}
    />
  );
};

export const WidgetHost =
  process.env.DISABLE_SHADOW_DOM === 'true' ? Widget : ShadowDomWidgetHost;
