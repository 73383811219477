import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FunctionComponent, h } from 'preact';
import tinyColor from 'tinycolor2';

import { QRCodeSVG, FontAwesomeSvgIcon } from './lib';

export interface WhatsappInfoProps {
  onBack: () => void;
  whatsappLink: string;
  borderColor: string;
  contentBackgroundColor: string;
  fontColor: string;
  borderRadius: number;
  borderWidth: number;
}

const SECONDARY_COLOR_OPACITY = 0.6;

export const WhatsappInfo: FunctionComponent<WhatsappInfoProps> = ({
  onBack,
  whatsappLink,
  borderColor,
  contentBackgroundColor,
  fontColor,
  borderRadius,
  borderWidth,
}) => {
  const secondaryColor = tinyColor(fontColor)
    .setAlpha(SECONDARY_COLOR_OPACITY)
    .toRgbString();

  return (
    <article
      className="mateo-flex mateo-flex-col mateo-items-center mateo-border-2 mateo-border-solid mateo-px-4 mateo-py-4"
      style={{
        borderRadius: borderRadius * 0.5,
        borderColor,
        borderWidth,
        backgroundColor: contentBackgroundColor,
      }}
    >
      <header className="mateo-flex mateo-w-full mateo-items-center mateo-justify-between">
        <div className="mateo-flex mateo-items-center mateo-gap-x-2">
          <FontAwesomeSvgIcon
            icon={faWhatsapp}
            aria-hidden="true"
            className="!mateo-h-5 !mateo-w-5 mateo-rounded-full mateo-p-1.5 mateo-text-white"
            style={{
              background:
                'linear-gradient(180deg, rgba(87,209,99,1) 0%, rgba(35,179,58,1) 100%)',
              backgroundColor: 'rgb(87,209,99)',
            }}
          />
          <p className="mateo-text-sm" style={{ color: fontColor }}>
            WhatsApp
          </p>
        </div>
        <div>
          <FontAwesomeSvgIcon
            icon={faTimes}
            className="!mateo-h-5 !mateo-w-5 mateo-cursor-pointer"
            aria-hidden="true"
            style={{ color: fontColor }}
            onClick={onBack}
          />
        </div>
      </header>
      <div className="mateo-flex mateo-w-full mateo-flex-col mateo-items-center mateo-gap-y-2">
        <div>
          <h2
            className="mateo-m-0 mateo-text-center mateo-text-base mateo-font-normal"
            style={{ color: secondaryColor }}
          >
            Jetzt QR-Code scannen
          </h2>
        </div>
        <div>
          <QRCodeSVG
            includeMargin
            value={whatsappLink}
            className="mateo-m-auto mateo-w-full mateo-rounded-sm"
          />
        </div>
        <div>
          <p
            className="mateo-m-0 mateo-text-center mateo-text-base"
            style={{ color: secondaryColor }}
          >
            oder
          </p>
        </div>
        <div className="mateo-flex mateo-w-full mateo-self-stretch mateo-py-2">
          <a
            href={whatsappLink}
            target="_blank"
            rel="noopener noreferrer nofollower"
            className="mateo-m-0 mateo-w-full mateo-border-2 mateo-border-solid mateo-px-2 mateo-py-4 mateo-text-center mateo-text-sm mateo-font-semibold"
            style={{
              borderColor,
              color: fontColor,
              borderRadius: borderRadius * 0.5,
              borderWidth,
            }}
          >
            Chat via WhatsApp-Web
          </a>
        </div>
      </div>
    </article>
  );
};
